<mat-form-field>
  <input type="text" matInput [formControlName]="controlName" [matAutocomplete]="auto" required [readonly]="readonly" />
  <mat-autocomplete
    #auto="matAutocomplete"
    [displayWith]="displayFn(typeaheadOptions)"
    class="lookahead-input"
    classlist="lookahead-input"
  >
    <mat-option class="autocomplete-option" *ngFor="let option of (options$ | async)" [value]="option">
      {{ option.label }}
    </mat-option>
  </mat-autocomplete>
  <mat-icon color="accent" *ngIf="valid" matSuffix>done</mat-icon>
</mat-form-field>
