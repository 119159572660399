import { Component, Input, OnInit, SkipSelf } from '@angular/core';
import { AbstractControl, ControlContainer, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TypeaheadOption } from './lookahead-options';

type LookaheadOption = { displayName?: string } | { firstName: string; lastName: string };

@Component({
  selector: 'app-lookahead-input',
  templateUrl: './lookahead-input.component.html',
  styleUrls: ['./lookahead-input.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: (container: ControlContainer) => container,
      deps: [[new SkipSelf(), ControlContainer]]
    }
  ]
})
export class LookaheadInputComponent implements OnInit {
  @Input() typeaheadOptions: TypeaheadOption[] = [];

  @Input() readonly = false;
  @Input() control: AbstractControl;

  options$: Observable<TypeaheadOption[]>;

  get controlName(): string {
    const group = <FormGroup>this.control?.parent;
    const key = Object.keys(group.controls).find(key => this.control === group.controls[key]);
    return key;
  }

  get valid(): boolean {
    return this.control?.valid;
  }

  displayFn(options: TypeaheadOption[]) {
    return (option) => this.displayLookaheadOption(option, options);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  displayLookaheadOption(option: TypeaheadOption, options: TypeaheadOption[]): string {
    return options?.find(a => a?.value === option.value)?.label;
  }

  ngOnInit(): void {
    this.initializeValidators();
    this.initializeLookaheadInputOptions();
  }

  private initializeValidators(): void {
    // This method sets the validators for the programTypePropControl and venuePropControl form controls.
    // The validators use the lookaheadInputValidator method to ensure that the selected value is a valid option from the provided list.
    this.control?.setValidators(
      Validators.compose([this.lookaheadInputValidator(this.typeaheadOptions)])
    );
  }

  private lookaheadInputValidator(options: TypeaheadOption[]): ValidatorFn {
    // This method returns a validator function that checks if the selected value is a valid option from the provided list of options.
    // The method uses the getDisplayName method to retrieve the display name of the selected value and compares it to the display names of the available options.
    // If the selected value is not found in the list, an invalidInput error is returned.
    return (control: AbstractControl<TypeaheadOption>): ValidationErrors | null => {
      const index = options?.findIndex(option => option.value === control.value.value);
      return index < 0 ? { invalidInput: control.value } : null;
    };
  }

  /**
   * Initializes the autocomplete options for ProgramType, Venue inputs.
   */
  private initializeLookaheadInputOptions(): void {
    // Set the options for the ProgramType and Venue inputs.
    this.options$ = this.getLookaheadOptions$(
      this.control,
      this.typeaheadOptions
    );
  }

  /**
   * Gets the lookahead options for an autocomplete input control
   *
   * @param control The control to get the lookahead options for
   * @param options The options to get the lookahead options from
   * @returns The lookahead options as an observable
   */
  private getLookaheadOptions$(
    control: AbstractControl,
    options: TypeaheadOption[]
  ): Observable<TypeaheadOption[]> {
    // Return an observable of the control value changes, filtered based on the options
    return control?.valueChanges.pipe(map((option: TypeaheadOption) => {
      // If there is no input provided, return all the options
      if (!option) {
        return options;
      }

      const inputValue = typeof(option) === 'string' ? (option as string)?.toLowerCase() : option?.label?.toLowerCase();

      return options?.filter(option => option?.label?.toLowerCase().includes(inputValue)) || [];
    }));
  }
}
